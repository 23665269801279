import React, { useCallback, useEffect, useRef, useState } from "react"
import Layout from "../components/Layout"
import styles from "./banan.module.css"
import Content from "../components/Content"
import route from "./track.json"
import {
  bisector,
  scaleLinear,
  axisBottom,
  axisLeft,
  max,
  min,
  line,
  select,
  pointer,
} from "d3"
import "leaflet/dist/leaflet.css"
import along from "@turf/along"
import SEO from "../components/Seo"

export default function Course() {
  const mapContainerRef = useRef()
  const [L, setL] = useState()
  const [map, setMap] = useState()
  const highlightMarker = useRef()
  const [highlightDistance, setHighlightDistance] = useState()
  useEffect(() => {
    const isSSR = typeof window === "undefined"
    let map

    if (!isSSR) {
      import("leaflet").then(L => {
        setL(L)

        const baseLayer = mapboxLayer("mapbox/outdoors-v11")
        const satelliteLayer = mapboxLayer("mapbox/satellite-v9")
        const routeLayer = createRouteLayer()
        map = L.map(mapContainerRef.current, {
          layers: [baseLayer, routeLayer],
        })
        L.control
          .layers({ Karta: baseLayer, Flygfoto: satelliteLayer })
          .addTo(map)
        map.fitBounds(routeLayer.getBounds())

        function mapboxLayer(id) {
          return L.tileLayer(
            "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
            {
              attribution:
                'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
              maxZoom: 18,
              id,
              tileSize: 512,
              zoomOffset: -1,
              accessToken:
                "pk.eyJ1IjoibGllZG1hbiIsImEiOiJja2ZqdHU5ZzYwY21qMnJuenV6bzF2bGxsIn0.SWGlmM3Tnkc7lrU2HZrq5w",
            }
          )
        }

        function createRouteLayer() {
          const styles = [
            {
              weight: 10,
              color: "black",
              opacity: 0.5,
            },
            {
              weight: 9,
              color: "white",
              opacity: 0.9,
            },
            {
              weight: 3,
              color: "#c53030",
              opacity: 1,
            },
          ]

          return L.geoJSON(
            Array.from({ length: 3 }).map((_, index) => ({
              ...route,
              properties: { index },
            })),
            { style: f => styles[f.properties.index] }
          )
        }
        setMap(map)
      })
    }

    return () => {
      if (map) {
        map.remove()
      }
    }
  }, [])

  useEffect(() => {
    if (!L || !map) return

    if (highlightDistance) {
      const {
        geometry: { coordinates },
      } = along(route, highlightDistance)
      const ll = [coordinates[1], coordinates[0]]

      if (!highlightMarker.current) {
        highlightMarker.current = L.circleMarker(ll, {
          radius: 4,
          color: "#2b6cb0",
          opacity: 1,
          fillColor: "white",
          fillOpacity: 1,
        }).addTo(map)
      } else {
        highlightMarker.current.setLatLng(ll)
      }
    } else if (highlightMarker.current) {
      map.removeLayer(highlightMarker.current)
      highlightMarker.current = null
    }
  }, [highlightDistance, map, L])

  return (
    <>
      <SEO
        title="Banan"
        description="Black Friday Trail Runs bana mäter strax under 9 km små stigar med utmanande kupering."
      />
      <Layout>
        <Content>
          <div>
            <p>
              Banan, som mäter strax under nio kilometer, utgörs främst av små
              stigar och utmanande kupering. Den börjar vid Tolereds AIK:s
              klubbstuga nära Tuvevallen och tar först en sväng genom
              Hisingsparkens norra delar, upp och ner för några mindre kullar.
            </p>
            <p>
              Därefter viker den av mot sydöst och Gunnestorps mosse, innan det
              är dags för banans första rejäla stigning, upp mot Norra Toppen.
              Där belönas man med en fantastisk utsikt över en stor del av
              Göteborg, innan det bär av ned mot Slätta Damm.
            </p>
            <p>
              Nästa stigning är mot Södra Toppen, följt av ett lite mer tekniskt
              men flackt parti innan den sista rejäla kullen. Man rundar parkens
              bågskyttebana och tar en lite större grusväg innan den sista
              svängen på kullen ovanför klubbstugan. Målgång framför
              klubbstugan.
            </p>
            <p>
              Tänk på att banan till stor del går över oupplysta stigar i
              kolmörker &mdash; pannlampa krävs! I slutet av november kommer det
              sannolikt vara ganska blött eller till och med isigt, så välj skor
              med bra grepp!
            </p>
            <p>
              <em>Barnbanan på 2,5 km</em> är en avkortad version av den långa
              banan, där du får känna på några av de inledande backarna, springa
              reflexbana genom kolmörk skog och upplopp med marshaller.
            </p>
          </div>
          <div className={styles.map} ref={mapContainerRef} />
          {L && (
            <ElevationProfile
              geojson={route}
              onHighlight={setHighlightDistance}
              L={L}
            />
          )}
        </Content>
      </Layout>
    </>
  )
}

function ElevationProfile({ geojson: { geometry: geojson }, onHighlight, L }) {
  const [dimensions, setDimensions] = useState()
  const containerRef = useCallback(
    (() => {
      const cb = node => {
        cb.current = node
        if (node) {
          setDimensions(node.getBoundingClientRect())
        }
      }
      return cb
    })(),
    []
  )
  const [stats, setStats] = useState()

  useEffect(() => {
    if (!dimensions) return

    const margin = { top: 4, right: 10, bottom: 20, left: 36 },
      width = dimensions.width - margin.left - margin.right,
      height = dimensions.height - margin.top - margin.bottom

    const bisect = bisector(function (d) {
      return d.distance
    }).left

    const x = scaleLinear().range([0, width])
    const y = scaleLinear().range([height, 0])

    // Define the axes
    const xAxis = axisBottom(x).ticks(5)
    const yAxis = axisLeft(y).ticks(5)

    const valueline = line()
      .x(function (d) {
        return x(d.distance)
      })
      .y(function (d) {
        return y(d.elevation)
      })

    const svg = select(containerRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    const data = geojson.coordinates.reduce(
      function (a, c) {
        const threshold = 2.5
        const ll = L.latLng([c[1], c[0], c[2]])
        let elevDiff
        let dist

        if (a.lastLatLng) {
          dist = a.lastLatLng.distanceTo(ll)
          a.accDist += dist
          elevDiff = ll.alt - a.baseLine

          if (Math.abs(elevDiff) > threshold) {
            if (elevDiff > 0) {
              a.accClimb += elevDiff
            } else {
              a.accDesc -= elevDiff
            }
            a.baseLine = ll.alt
          }
        } else {
          a.baseLine = ll.alt
        }

        a.data.push({
          distance: a.accDist / 1000,
          elevation: c[2],
        })
        a.lastLatLng = ll
        return a
      },
      {
        baseLine: null,
        accDist: 0,
        accClimb: 0,
        accDesc: 0,
        data: [],
      }
    )

    setStats(data)

    // Scale the range of the data
    const xMax = max(data.data, function (d) {
      return d.distance
    })
    const yMin =
      Math.floor(
        min(data.data, function (d) {
          return d.elevation
        }) / 10
      ) * 10
    x.domain([0, xMax])
    y.domain([
      yMin,
      Math.ceil(
        max(data.data, function (d) {
          return d.elevation
        }) / 10
      ) * 10,
    ])

    // Add the valueline path.
    svg
      .append("path")
      .attr("class", styles.fill)
      .attr(
        "d",
        valueline(
          [{ distance: 0, elevation: yMin }]
            .concat(data.data)
            .concat([{ distance: xMax, elevation: yMin }])
        )
      )
    svg
      .append("path")
      .attr("class", styles.stroke)
      .attr("d", valueline(data.data))

    // Add the X Axis
    svg
      .append("g")
      .attr("class", `x ${styles.axis}`)
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis)

    // Add the Y Axis
    svg.append("g").attr("class", `y ${styles.axis}`).call(yAxis)

    const focus = svg
      .append("g")
      .attr("class", styles.focus)
      .style("display", "none")

    focus.append("circle").attr("r", 4.5)

    focus.append("text").attr("x", 9).attr("dy", ".35em")

    svg
      .append("rect")
      .attr("class", styles.overlay)
      .attr("width", width)
      .attr("height", height)
      .on(
        "mouseover",
        L.bind(function () {
          focus.style("display", null)
          // this.fire("mouseover")
        }, this)
      )
      .on(
        "mouseout",
        L.bind(function () {
          focus.style("display", "none")
          // this.fire("mouseout")
        }, this)
      )
      .on("click", mousemove)
      .on("mousemove", mousemove)

    function mousemove(e) {
      const mouseCoord = pointer(e)
      const x0 = x.invert(mouseCoord[0])
      const i = bisect(data.data, x0, 1)
      const d0 = data.data[i - 1]
      const d1 = data.data[i]
      const isD1Closest = x0 - d0.distance > d1.distance - x0
      const d = isD1Closest ? d1 : d0
      focus.attr(
        "transform",
        "translate(" + x(d.distance) + "," + y(d.elevation) + ")"
      )
      focus
        .select("text")
        .text(d.distance.toFixed(1) + " km: " + d.elevation.toFixed(0) + " m")

      onHighlight(d.distance)
    }
  }, [dimensions, L, containerRef, geojson, onHighlight])

  return (
    <div className={styles.elevationProfile}>
      <div className={styles.graphContainer} ref={containerRef} />
      {stats && (
        <div>
          Total stigning: <em>{stats.accClimb.toFixed(0)} meter, </em>
          <a href="https://www.stifa.se/">Stifa </a>
          <em>{(stats.accClimb / (stats.accDist / 1000)).toFixed(1)}</em>
        </div>
      )}
    </div>
  )
}
